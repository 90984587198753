<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nova Unidade" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectImplantacoes || !selectImplantacoes.length" />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <!-- Tabela de Implantaçoes -->
        <DataTable ref="dt" :value="faturas" v-model:expandedRows="selectImplantacoes" dataKey="id" 
        :paginator="true" :rows="50" :filters="filters" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
        :rowsPerPageOptions="[5, 10, 25]" currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)" 
        responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gereneciar Implantações</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column expander style="width: 5rem" />
          
          <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:5rem;">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="unidade" header="Unidade" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Unidade</span>
              {{ slotProps.data.unidade }}
            </template>
          </Column>
          <Column field="razao_social" header="Empresa" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Empresa</span>
              {{ slotProps.data.razao_social }}
            </template>
          </Column>

          <Column field="descricao_insumo" header="Insumo" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Insumo</span>
              {{ slotProps.data.descricao_insumo }}
            </template>
          </Column>
          <Column field="descricao_modalidade" header="Modalidade" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Modalidade</span>
              {{ slotProps.data.descricao_modalidade }}
            </template>
          </Column>
          <Column field="descricao_concessionaria" header="Concessionaria" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Concessionaria</span>
              {{ slotProps.data.descricao_concessionaria }}
            </template>
          </Column>

          <Column field="descricao_pagamento" header="Pagamento" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Pagamento</span>
              {{ slotProps.data.descricao_pagamento }}
            </template>
          </Column>
          <Column field="descricao_classe" header="Classe" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Classe</span>
              {{ slotProps.data.descricao_classe }}
            </template>
          </Column>
          <Column field="descricao_subclasse" header="Subclasse" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Subclasse</span>
              {{ slotProps.data.descricao_subclasse }}
            </template>
          </Column>
          <Column field="descricao_tensao" header="Tensao" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Tensao</span>
              {{ slotProps.data.descricao_tensao }}
            </template>
          </Column>
          <Column field="descricao_ligacao" header="Ligacao" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Ligacao</span>
              {{ slotProps.data.descricao_ligacao }}
            </template>
          </Column>
          <Column field="nome_usuario" header="Usuario" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Usuario</span>
              {{ slotProps.data.nome_usuario }}
            </template>
          </Column>

          <Column field="mes_referencia" header="Mes Referencia" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Mes Referencia</span>
              {{ slotProps.data.mes_referencia }}
            </template>
          </Column>
          <Column field="data_pagamento" header="Data Pagamento" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Data Pagamento</span>
              {{ slotProps.data.data_pagamento }}
            </template>
          </Column>
          <Column field="data_vencimento" header="Data Vencimento" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Data Vencimento</span>
              {{ slotProps.data.data_vencimento }}
            </template>
          </Column>
          <Column field="data_emissao_fatura" header="Data Emissao Fatura" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Data Emissao Fatura</span>
              {{ slotProps.data.data_emissao_fatura }}
            </template>
          </Column>
          <Column field="status" header="Status" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              {{ slotProps.data.status }}
            </template>
          </Column>

          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="apagarFaturasModal(slotProps.data)" />
            </template>
          </Column>
          
          <!--Tabelas adicionais-->
          <template #expansion="slotProps">
            <div class="p-3">
                <h5>Dados</h5>
                <DataTable :value="slotProps.data.dados_faturas">
                  <Column field="id_fatura" header="Id Fatura" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps">
                      <span class="p-column-title">Id Fatura</span>
                      {{ slotProps.data.id_fatura }}
                    </template>
                  </Column>
                    <Column field="id_bandeira" header="Id Bandeira" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Id Bandeira</span>
                        {{ slotProps.data.id_bandeira }}
                      </template>
                    </Column>
                    <Column field="nome_empresa" header="Nome Empresa" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Nome Empresa</span>
                        {{ slotProps.data.nome_empresa }}
                      </template>
                    </Column>
                    <Column field="endereco" header="Endereço" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Endereço</span>
                        {{ slotProps.data.endereco }}
                      </template>
                    </Column>
                    <Column field="cnpj" header="CNPJ" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">CNPJ</span>
                        {{ slotProps.data.cnpj }}
                      </template>
                    </Column>
                    <Column field="total_fatura" header="Total Fatura" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Total Fatura</span>
                        {{ slotProps.data.total_fatura }}
                      </template>
                    </Column>
                    <Column field="data_leitura_anterior" header="Data Leitura Anterior" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Data Leitura Anterior</span>
                        {{ slotProps.data.data_leitura_anterior }}
                      </template>
                    </Column>
                    <Column field="data_leitura_atual" header="Data Leitura Atual" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Data Leitura Atual</span>
                        {{ slotProps.data.data_leitura_atual }}
                      </template>
                    </Column>
                    <Column field="data_proxima_leitura" header="Data Proxima Leitura" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Data Proxima Leitura</span>
                        {{ slotProps.data.data_proxima_leitura }}
                      </template>
                    </Column>
                    <Column field="constante" header="Constante" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Constante</span>
                        {{ slotProps.data.constante }}
                      </template>
                    </Column>
                    <Column field="consumo" header="Consumo" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Consumo</span>
                        {{ slotProps.data.consumo }}
                      </template>
                    </Column>
                    <Column field="dias_fatura" header="Dias fatura" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Dias fatura</span>
                        {{ slotProps.data.dias_fatura }}
                      </template>
                    </Column>
                    <Column field="conta_contrato" header="Conta Contrato" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Conta Contrato</span>
                        {{ slotProps.data.conta_contrato }}
                      </template>
                    </Column>
                    <Column field="inscricao_estadual" header="Inscrição Estadual" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Inscrição Estadual</span>
                        {{ slotProps.data.inscricao_estadual }}
                      </template>
                    </Column>
                    <Column field="conta_debito" header="Conta Debito" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Conta Debito</span>
                        {{ slotProps.data.conta_debito }}
                      </template>
                    </Column>
                    <Column field="informacoes" header="Informacoes" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                      <template #body="slotProps">
                        <span class="p-column-title">Informacoes</span>
                        {{ slotProps.data.informacoes }}
                      </template>
                    </Column>
                    <Column headerStyle="min-width:10rem;">
                      <template #body="slotProps">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editDadosFatura(slotProps.data)" />
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="apagarDadosFaturaModal(slotProps.data)" />
                      </template>
                    </Column>
                </DataTable>
            </div>

            <div class="p-3">
              <h5>Dados Fiscais</h5>
              <DataTable :value="slotProps.data.dados_fiscais_faturas">
                <Column field="nota_fiscal" header="Nota Fiscal" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="slotProps" >
                    <span class="p-column-title">Nota Fiscal</span>
                      {{ slotProps.data.nota_fiscal }}
                    </template>
                  </Column>
                  <Column field="serie_nota_fiscal" header="Serie Nota Fiscal" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Serie Nota Fiscal</span>
                      {{ slotProps.data.serie_nota_fiscal }}
                    </template>
                  </Column>
                  <Column field="fisco" header="Fisco" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Fisco</span>
                      {{ slotProps.data.fisco }}
                    </template>
                  </Column>
                  <Column field="qr_code_chave_acesso" header="Chave de acesso" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Chave de acesso</span>
                      {{ slotProps.data.qr_code_chave_acesso }}
                    </template>
                  </Column>
                  <Column field="qr_code_protocolo" header="Protocolo" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Protocolo</span>
                      {{ slotProps.data.qr_code_protocolo }}
                    </template>
                  </Column>
                  <Column field="codigo_de_barras" header="Codigo de Barras" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Codigo de Barras</span>
                      {{ slotProps.data.codigo_de_barras }}
                    </template>
                  </Column>
                  <Column headerStyle="min-width:10rem;">
                    <template #body="slotProps">
                      <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editDadosFiscais(slotProps.data)" />
                      <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="apagarDadosFiscaisModal(slotProps.data)" />
                    </template>
                  </Column>
                </DataTable>
            </div>

            <div class="p-3">
              <h5>Dados Tributos</h5>
              <DataTable :value="slotProps.data.dados_tributos_faturas">
                <Column field="icms_base" header="ICMS Base" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="slotProps" >
                    <span class="p-column-title">ICMS Base</span>
                      {{ slotProps.data.icms_base }}
                    </template>
                  </Column>
                  <Column field="icms_aliquota" header="ICMS Aliquota" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">ICMS Aliquota</span>
                      {{ slotProps.data.icms_aliquota }}
                    </template>
                  </Column>
                  <Column field="icms_valor" header="ICMS Valor" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">ICMS Valor</span>
                      {{ slotProps.data.icms_valor }}
                    </template>
                  </Column>
                  <Column field="pis_base" header="PIS Base" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">PIS Base</span>
                      {{ slotProps.data.pis_base }}
                    </template>
                  </Column>
                  <Column field="pis_aliquota" header="PIS Aliquota" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">PIS Aliquota</span>
                      {{ slotProps.data.pis_aliquota }}
                    </template>
                  </Column>
                  <Column field="pis_valor" header="PIS Valor" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">PIS Valor</span>
                      {{ slotProps.data.pis_valor }}
                    </template>
                  </Column>
                  <Column field="cofins_base" header="COFINS Base" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">COFINS Base</span>
                      {{ slotProps.data.cofins_base }}
                    </template>
                  </Column>
                  <Column field="cofins_aliquota" header="COFINS Aliquota" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">COFINS Aliquota</span>
                      {{ slotProps.data.cofins_aliquota }}
                    </template>
                  </Column>
                  <Column field="cofins_valor" header="COFINS Valor" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">COFINS Valor</span>
                      {{ slotProps.data.cofins_valor }}
                    </template>
                  </Column>
                  <Column headerStyle="min-width:10rem;">
                    <template #body="slotProps">
                      <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editDadosTributos(slotProps.data)" />
                      <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="apagarDadosTributosModal(slotProps.data)" />
                    </template>
                  </Column>
                </DataTable>
            </div>

            <div class="p-3">
              <div class="grid">
                <div class="col-fixed" style="width:200px">
                  <h5>Dados Medidor</h5>
                </div>
                <div class="col">
                  <Button label="Novo Medidor" icon="pi pi-plus" class="p-button-success mr-2" @click="addDadosMedidor(slotProps.data)" />
                </div>
              </div>
                <DataTable :value="slotProps.data.medidor_faturas">
                  <Column field="medidor" header="Medidor" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Medidor</span>
                      {{ slotProps.data.medidor }}
                    </template>
                  </Column>
                  <Column field="leitura_anterior" header="Leitura Anterior" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Leitura Anterior</span>
                      {{ slotProps.data.leitura_anterior }}
                    </template>
                  </Column>
                  <Column field="leitura_atual" header="Leitura Atual" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Leitura Atual</span>
                      {{ slotProps.data.leitura_atual }}
                    </template>
                  </Column>
                  <Column headerStyle="min-width:10rem;">
                    <template #body="slotProps">
                      <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editDadosMedidor(slotProps.data)" />
                      <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="apagarDadosMedidorModal(slotProps.data)" />
                    </template>
                  </Column>
                </DataTable>
            </div>

            <div class="p-3">
              <div class="grid">
                <div class="col-fixed" style="width:200px">
                  <h5>Itens Faturados</h5>
                </div>
                <div class="col">
                  <Button label="Novo Medidor" icon="pi pi-plus" class="p-button-success mr-2" @click="addItensFaturados(slotProps.data)" />
                </div>
              </div>
                <DataTable :value="slotProps.data.itens_faturados">
                  <Column field="id_item_normalizado" header="Id Item Normalizado" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Id Item Normalizado</span>
                      {{ slotProps.data.id_item_normalizado }}
                    </template>
                  </Column>
                  <Column field="descricao" header="Descricao" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Descricao</span>
                      {{ slotProps.data.descricao }}
                    </template>
                  </Column>
                  <Column field="quantidade" header="Quantidade" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Quantidade</span>
                      {{ slotProps.data.quantidade }}
                    </template>
                  </Column>
                  <Column field="tarifa_com_tributos" header="Tarifa com Tributos" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Tarifa com Tributos</span>
                      {{ slotProps.data.tarifa_com_tributos }}
                    </template>
                  </Column>
                  <Column field="valor" header="Valor" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Valor</span>
                      {{ slotProps.data.valor }}
                    </template>
                  </Column>
                  <Column field="valor_pis_cofins" header="Valor PIS/COFINS" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Valor PIS/COFINS</span>
                      {{ slotProps.data.valor_pis_cofins }}
                    </template>
                  </Column>
                  <Column field="valor_base_calculo_icms" header="Valor Base ICMS" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Valor Base ICMS</span>
                      {{ slotProps.data.valor_base_calculo_icms }}
                    </template>
                  </Column>
                  <Column field="valor_base_calculo_pis_cofins" header="Valor Base PIS/COFINS" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Valor Base PIS/COFINS</span>
                      {{ slotProps.data.valor_base_calculo_pis_cofins }}
                    </template>
                  </Column>
                  <Column field="aliquota_icms" header="Aliquota ICMS" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Aliquota ICMS</span>
                      {{ slotProps.data.aliquota_icms }}
                    </template>
                  </Column>
                  <Column field="valor_icms" header="Valor ICMS" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Valor ICMS</span>
                      {{ slotProps.data.valor_icms }}
                    </template>
                  </Column>
                  <Column field="valor_pis" header="Valor PIS" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Valor PIS</span>
                      {{ slotProps.data.valor_pis }}
                    </template>
                  </Column>
                  <Column field="valor_cofins" header="Valor Cofins" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Valor Cofins</span>
                      {{ slotProps.data.valor_cofins }}
                    </template>
                  </Column>
                  <Column field="tarifa_sem_tributos" header="Tarifa Sem Tributos" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">Tarifa Sem Tributos</span>
                      {{ slotProps.data.tarifa_sem_tributos }}
                    </template>
                  </Column>
                  <Column field="cci" header="CCI" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="slotProps" >
                      <span class="p-column-title">CCI</span>
                      {{ slotProps.data.cci }}
                    </template>
                  </Column>
                  <Column headerStyle="min-width:10rem;">
                    <template #body="slotProps">
                      <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItensFaturados(slotProps.data)" />
                      <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="apagarItensFaturadosModal(slotProps.data)" />
                    </template>
                  </Column>
                </DataTable>
            </div>
          </template>
        </DataTable>
        


        <!-- ############################################################################################################################################################################ -->
        <!-- Modais de Edição-->
        <!-- Modal de edição do medidor-->
        <Dialog v-model:visible="modalEditMedidor" :style="{ width: '650px' }" header="Editar Medidor" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Medidor</label>
            <InputText id="bome" v-model.trim="medidor.medidor" required="true" autofocus :class="{ 'p-invalid': submitted && !medidor.medidor }" />
            <small class="p-invalid" v-if="submitted && !medidor.medidor">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Leitura Anterior</label>
            <InputText id="leitura_anterior" v-model.trim="medidor.leitura_anterior" required="true" autofocus :class="{ 'p-invalid': submitted && !medidor.leitura_anterior }" />
            <small class="p-invalid" v-if="submitted && !medidor.leitura_anterior">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Leitura atual</label>
            <InputText id="leitura_atual" v-model.trim="medidor.leitura_atual" required="true" autofocus :class="{ 'p-invalid': submitted && !medidor.leitura_atual }" />
            <small class="p-invalid" v-if="submitted && !medidor.leitura_atual">Razão Social é obrigatória.</small>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarMedidorFatura" />
          </template>
        </Dialog>

        <!-- Modal de edição dos dados fiscais-->
        <Dialog v-model:visible="modalEditDadosFiscais" :style="{ width: '650px' }" header="Editar Medidor" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Nota Fiscal</label>
            <InputText id="nota_fiscal" v-model.trim="dados_fiscais.nota_fiscal" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_fiscais.nota_fiscal }" />
            <small class="p-invalid" v-if="submitted && !dados_fiscais.nota_fiscal">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Serie</label>
            <InputText id="serie_nota_fiscal" v-model.trim="dados_fiscais.serie_nota_fiscal" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_fiscais.serie_nota_fiscal }" />
            <small class="p-invalid" v-if="submitted && !dados_fiscais.serie_nota_fiscal">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Fisco</label>
            <InputText id="fisco" v-model.trim="dados_fiscais.fisco" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_fiscais.fisco }" />
            <small class="p-invalid" v-if="submitted && !dados_fiscais.fisco">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Chave de Acesso</label>
            <InputText id="qr_code_chave_acesso" v-model.trim="dados_fiscais.qr_code_chave_acesso" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_fiscais.qr_code_chave_acesso }" />
            <small class="p-invalid" v-if="submitted && !dados_fiscais.qr_code_chave_acesso">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Protocolo</label>
            <InputText id="qr_code_protocolo" v-model.trim="dados_fiscais.qr_code_protocolo" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_fiscais.qr_code_protocolo }" />
            <small class="p-invalid" v-if="submitted && !dados_fiscais.qr_code_protocolo">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Codigo de Barras</label>
            <InputText id="codigo_de_barras" v-model.trim="dados_fiscais.codigo_de_barras" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_fiscais.codigo_de_barras }" />
            <small class="p-invalid" v-if="submitted && !dados_fiscais.codigo_de_barras">Razão Social é obrigatória.</small>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarDadosFicaisFatura" />
          </template>
        </Dialog>

        <!-- Modal de edição dos dados tributos-->
        <Dialog v-model:visible="modalEditDadosTributos" :style="{ width: '650px' }" header="Editar Medidor" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">ICMS Base</label>
            <InputText id="icms_base" v-model.trim="dados_tributos.icms_base" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_tributos.icms_base }" />
            <small class="p-invalid" v-if="submitted && !dados_tributos.icms_base">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">ICMS Aliquota</label>
            <InputText id="icms_aliquota" v-model.trim="dados_tributos.icms_aliquota" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_tributos.icms_aliquota }" />
            <small class="p-invalid" v-if="submitted && !dados_tributos.icms_aliquota">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">ICMS Valor</label>
            <InputText id="icms_valor" v-model.trim="dados_tributos.icms_valor" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_tributos.icms_valor }" />
            <small class="p-invalid" v-if="submitted && !dados_tributos.icms_valor">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">PIS Base</label>
            <InputText id="pis_base" v-model.trim="dados_tributos.pis_base" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_tributos.pis_base }" />
            <small class="p-invalid" v-if="submitted && !dados_tributos.pis_base">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">PIS Aliquota</label>
            <InputText id="pis_aliquota" v-model.trim="dados_tributos.pis_aliquota" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_tributos.pis_aliquota }" />
            <small class="p-invalid" v-if="submitted && !dados_tributos.pis_aliquota">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">PIS Valor</label>
            <InputText id="pis_valor" v-model.trim="dados_tributos.pis_valor" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_tributos.pis_valor }" />
            <small class="p-invalid" v-if="submitted && !dados_tributos.pis_valor">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">COFINS Base</label>
            <InputText id="cofins_base" v-model.trim="dados_tributos.cofins_base" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_tributos.cofins_base }" />
            <small class="p-invalid" v-if="submitted && !dados_tributos.cofins_base">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">COFINS Aliquota</label>
            <InputText id="cofins_aliquota" v-model.trim="dados_tributos.cofins_aliquota" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_tributos.cofins_aliquota }" />
            <small class="p-invalid" v-if="submitted && !dados_tributos.cofins_aliquota">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">COFINS Valor</label>
            <InputText id="cofins_valor" v-model.trim="dados_tributos.cofins_valor" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_tributos.cofins_valor }" />
            <small class="p-invalid" v-if="submitted && !dados_tributos.cofins_valor">Razão Social é obrigatória.</small>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarDadosTributosFatura" />
          </template>
        </Dialog>

        <!-- Modal de edição dos Itens da fatura-->
        <Dialog v-model:visible="modalEditItens" :style="{ width: '650px' }" header="Editar Item" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Id Item Normalizado</label>
            <InputText id="bome" v-model.trim="itens_faturados.id_item_normalizado" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.id_item_normalizado }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.id_item_normalizado">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Descrição</label>
            <InputText id="descricao" v-model.trim="itens_faturados.descricao" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.descricao }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.descricao">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Quantidade</label>
            <InputText id="quantidade" v-model.trim="itens_faturados.quantidade" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.quantidade }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.quantidade">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Tarifa com Tributos</label>
            <InputText id="tarifa_com_tributos" v-model.trim="itens_faturados.tarifa_com_tributos" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.tarifa_com_tributos }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.tarifa_com_tributos">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Valor</label>
            <InputText id="valor" v-model.trim="itens_faturados.valor" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.valor }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.valor">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Valor Pis/Cofins</label>
            <InputText id="valor_pis_cofins" v-model.trim="itens_faturados.valor_pis_cofins" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.valor_pis_cofins }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.valor_pis_cofins">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Valor Base Icms</label>
            <InputText id="valor_base_calculo_icms" v-model.trim="itens_faturados.valor_base_calculo_icms" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.valor_base_calculo_icms }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.valor_base_calculo_icms">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Valor Base Pis/Cofins</label>
            <InputText id="valor_base_calculo_pis_cofins" v-model.trim="itens_faturados.valor_base_calculo_pis_cofins" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.valor_base_calculo_pis_cofins }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.valor_base_calculo_pis_cofins">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Aliquota Icms</label>
            <InputText id="aliquota_icms" v-model.trim="itens_faturados.aliquota_icms" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.aliquota_icms }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.aliquota_icms">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Valor Icms</label>
            <InputText id="valor_icms" v-model.trim="itens_faturados.valor_icms" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.valor_icms }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.valor_icms">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Valor Pis</label>
            <InputText id="valor_pis" v-model.trim="itens_faturados.valor_pis" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.valor_pis }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.valor_pis">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Valor Cofins</label>
            <InputText id="valor_cofins" v-model.trim="itens_faturados.valor_cofins" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.valor_cofins }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.valor_cofins">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Tarifa Sem Tributos</label>
            <InputText id="tarifa_sem_tributos" v-model.trim="itens_faturados.tarifa_sem_tributos" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.tarifa_sem_tributos }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.tarifa_sem_tributos">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">CCI</label>
            <InputText id="cci" v-model.trim="itens_faturados.cci" required="true" autofocus :class="{ 'p-invalid': submitted && !itens_faturados.cci }" />
            <small class="p-invalid" v-if="submitted && !itens_faturados.cci">Razão Social é obrigatória.</small>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarItensFaturadosFatura" />
          </template>
        </Dialog>
      
        <!-- Modal de edição de Fatura -->
        <Dialog v-model:visible="productDialog" :style="{ width: '650px' }" header="Editar Fatura" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Unidade</label>
            <InputText id="bome" v-model.trim="fatura.unidade" required="true" disabled autofocus :class="{ 'p-invalid': submitted && !fatura.unidade }" />
            <small class="p-invalid" v-if="submitted && !fatura.unidade">Razão Social é obrigatória.</small>
          </div>
          <div class="col-md-4">
            <label for="Tipo" class="mb-3">Empresa</label>
            <Dropdown id="id_empresa" required="true" v-model="fatura.id_empresa" :options="tipos_empresas" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !fatura.id_empresa }"></Dropdown>
            <small class="p-invalid" v-if="submitted && !fatura.id_empresa">Empresa é obrigatório.</small>
          </div>
          <div class="col-md-4">
            <label for="Tipo" class="mb-3">Insumo</label>
            <Dropdown id="id_tipo_insumo" required="true" v-model="fatura.id_tipo_insumo" :options="tipo_insumo" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !fatura.id_tipo_insumo }"></Dropdown>
            <small class="p-invalid" v-if="submitted && !fatura.id_tipo_insumo">Insumo é obrigatório.</small>
          </div>
          <div class="col-md-4">
            <label for="Tipo" class="mb-3">Modalidade</label>
            <Dropdown id="id_modalidade" required="true" v-model="fatura.id_modalidade" :options="tipos_modalidades" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !fatura.id_modalidade }"></Dropdown>
            <small class="p-invalid" v-if="submitted && !fatura.id_modalidade">Modalidade é obrigatório.</small>
          </div>
          <div class="col-md-4">
            <label for="Tipo" class="mb-3">Concessionaria</label>
            <Dropdown id="id_concessionaria" required="true" v-model="fatura.id_concessionaria" :options="tipos_concess" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !fatura.id_concessionaria }"></Dropdown>
            <small class="p-invalid" v-if="submitted && !fatura.id_concessionaria">Concessionaria é obrigatório.</small>
          </div>
          <div class="col-md-4">
            <label for="Tipo" class="mb-3">Pagamento</label>
            <Dropdown id="id_tipo_pagamento" required="true" v-model="fatura.id_tipo_pagamento" :options="tipos_pagamento" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !fatura.id_tipo_pagamento }"></Dropdown>
            <small class="p-invalid" v-if="submitted && !fatura.id_tipo_pagamento">Pagamento é obrigatório.</small>
          </div>
          <div class="col-md-4">
            <label for="Tipo" class="mb-3">Classe</label>
            <Dropdown id="id_classe" required="true" v-model="fatura.id_classe" :options="tipos_classe_tarifaria" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !fatura.id_classe }"></Dropdown>
            <small class="p-invalid" v-if="submitted && !fatura.id_classe">Classe é obrigatório.</small>
          </div>
          <div class="col-md-4">
            <label for="Tipo" class="mb-3">Subclasse</label>
            <Dropdown id="id_subclasse" required="true" v-model="fatura.id_subclasse" :options="tipos_subclasse" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !fatura.id_subclasse }"></Dropdown>
            <small class="p-invalid" v-if="submitted && !fatura.id_subclasse">Subclasse é obrigatório.</small>
          </div>
          <div class="col-md-4">
            <label for="Tipo" class="mb-3">Tensao</label>
            <Dropdown id="id_tensao" required="true" v-model="fatura.id_tensao" :options="tipos_tensao" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !fatura.id_tensao }"></Dropdown>
            <small class="p-invalid" v-if="submitted && !fatura.id_tensao">Tensao é obrigatório.</small>
          </div>
          <div class="col-md-4">
            <label for="Tipo" class="mb-3">Ligação</label>
            <Dropdown id="id_tipo_ligacao" required="true" v-model="fatura.id_tipo_ligacao" :options="tipos_ligacao" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !fatura.id_tipo_ligacao }"></Dropdown>
            <small class="p-invalid" v-if="submitted && !fatura.id_tipo_ligacao">Ligação é obrigatório.</small>
          </div>
          <div class="field">
            <label for="name">Usuario</label>
            <InputText id="nome_usuario" v-model.trim="fatura.nome_usuario" required="true" disabled autofocus :class="{ 'p-invalid': submitted && !fatura.nome_usuario }" />
            <small class="p-invalid" v-if="submitted && !fatura.nome_usuario">Razão Social é obrigatória.</small>
          </div>
          
          <div class="field">
            <label for="name">Mes Referencia</label>
            <Calendar id="mes_referencia" v-model="fatura.mes_referencia" dateFormat="yy-mm-dd" :class="{ 'p-invalid': submitted && !fatura.mes_referencia }"/>
            <small class="p-invalid" v-if="submitted && !fatura.mes_referencia">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Data Pagamento</label>
            <Calendar id="data_pagamento" v-model="fatura.data_pagamento" dateFormat="yy-mm-dd" :class="{ 'p-invalid': submitted && !fatura.data_pagamento }"/>
            <small class="p-invalid" v-if="submitted && !fatura.data_pagamento">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Data Vencimento</label>
            <Calendar id="data_vencimento" v-model="fatura.data_vencimento" dateFormat="yy-mm-dd" :class="{ 'p-invalid': submitted && !fatura.data_vencimento }"/>
            <small class="p-invalid" v-if="submitted && !fatura.data_vencimento">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Data emissao</label>
            <Calendar id="data_emissao_fatura" v-model="fatura.data_emissao_fatura" dateFormat="yy-mm-dd" :class="{ 'p-invalid': submitted && !fatura.data_emissao_fatura }"/>
            <small class="p-invalid" v-if="submitted && !fatura.data_emissao_fatura">Razão Social é obrigatória.</small>
          </div>
          
          <div class="field">
            <label for="name">Status</label>
            <InputText id="status" v-model.trim="fatura.status" required="true"  :class="{ 'p-invalid': submitted && !fatura.status }" />
            <small class="p-invalid" v-if="submitted && !fatura.status">Razão Social é obrigatória.</small>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarFatura" />
          </template>
        </Dialog>

        <!-- Modal de edição dos Itens da fatura-->
        <Dialog v-model:visible="modalEditDados" :style="{ width: '650px' }" header="Editar Item" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Id Bandeira</label>
            <InputText id="id_bandeira" v-model.trim="dados_faturas.id_bandeira" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.id_bandeira }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.id_bandeira">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Nome Empresa</label>
            <InputText id="nome_empresa" v-model.trim="dados_faturas.nome_empresa" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.nome_empresa }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.nome_empresa">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Endereço</label>
            <InputText id="endereco" v-model.trim="dados_faturas.endereco" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.endereco }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.endereco">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">CNPJ</label>
            <InputText id="cnpj" v-model.trim="dados_faturas.cnpj" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.cnpj }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.cnpj">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Valor</label>
            <InputText id="total_fatura" v-model.trim="dados_faturas.total_fatura" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.total_fatura }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.total_fatura">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Data Leitura Anterior</label>
            <InputText id="data_leitura_anterior" v-model.trim="dados_faturas.data_leitura_anterior" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.data_leitura_anterior }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.data_leitura_anterior">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Data Leitura Atual</label>
            <InputText id="data_leitura_atual" v-model.trim="dados_faturas.data_leitura_atual" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.data_leitura_atual }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.data_leitura_atual">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Data Proxima Leitura</label>
            <InputText id="data_proxima_leitura" v-model.trim="dados_faturas.data_proxima_leitura" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.data_proxima_leitura }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.data_proxima_leitura">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Constante</label>
            <InputText id="constante" v-model.trim="dados_faturas.constante" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.constante }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.constante">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Consumo</label>
            <InputText id="consumo" v-model.trim="dados_faturas.consumo" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.consumo }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.consumo">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Conta Contrato</label>
            <InputText id="conta_contrato" v-model.trim="dados_faturas.conta_contrato" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.conta_contrato }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.conta_contrato">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Inscrição Estadual</label>
            <InputText id="inscricao_estadual" v-model.trim="dados_faturas.inscricao_estadual" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.inscricao_estadual }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.inscricao_estadual">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Conta Debito</label>
            <InputText id="conta_debito" v-model.trim="dados_faturas.conta_debito" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.conta_debito }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.conta_debito">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Informações</label>
            <InputText id="informacoes" v-model.trim="dados_faturas.informacoes" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_faturas.informacoes }" />
            <small class="p-invalid" v-if="submitted && !dados_faturas.informacoes">Razão Social é obrigatória.</small>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarDadosFatura" />
          </template>
        </Dialog>





        <!-- ############################################################################################################################################################################ -->
        <!-- Modais de Exclusão-->
        <!-- Modal deletar faturas -->
        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="fatura">Certeza que vai apagar: <b>{{ fatura.descricao }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteProductDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarFaturas" />
          </template>
        </Dialog>

        <!-- Modal deletar itens da faturas -->
        <Dialog v-model:visible="deleteItensFaturadosDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="itens_faturados">Certeza que vai apagar: <b>{{ itens_faturados.descricao }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteItensFaturadosDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarItensFaturados" />
          </template>
        </Dialog>

        <!-- Modal deletar medidor -->
        <Dialog v-model:visible="deleteMedidorDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="medidor_faturas">Certeza que vai apagar: <b>{{ medidor_faturas.medidor }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteMedidorDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarDadosMedidor" />
          </template>
        </Dialog>

        <!-- Modal deletar Dados Fiscais -->
        <Dialog v-model:visible="deleteDadosFiscaisDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Certeza que vai apagar?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteDadosFiscaisDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarDadosFiscais" />
          </template>
        </Dialog>

        <!-- Modal deletar Dados Tributos -->
        <Dialog v-model:visible="deleteDadosTributosDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Certeza que vai apagar?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteDadosTributosDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarDadosTributos" />
          </template>
        </Dialog>

        <!-- Modal deletar dados -->
        <Dialog v-model:visible="deleteDadosDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="dados_faturas">Certeza que vai apagar?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteDadosDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarDados" />
          </template>
        </Dialog>

        <!-- Modal deletar varias faturas -->
        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="fatura">Apagar Tipos de Concessionarias selecionados?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>



      </div>
    </div>
  </div>
</template>

<script>

  import { FilterMatchMode } from "primevue/api";
  import funcoes from '../../funcoes.js';

  export default {
    mixins: [funcoes],
    data() {
      return {
        faturas: [],
        fatura: {},
        selectImplantacoes: null,
        productDialog: false,

        medidor:{},
        modalEditMedidor: false,
        medidorDialog: false,

        itens_faturados:{},
        modalEditItens: false,

        dados_faturas:{},
        modalEditDados: false,

        dados_fiscais:{},
        modalEditDadosFiscais: false,

        dados_tributos:{},
        modalEditDadosTributos: false,
        
        deleteProductDialog: false,
        deleteMedidorDialog: false,
        deleteDadosDialog: false,
        deleteDadosFiscaisDialog: false,
        deleteDadosTributosDialog: false,
        deleteItensFaturadosDialog: false,
        deleteProductsDialog: false,

        filters: {},
        submitted: false,
        
        tipos_empresas: [],
        desc_tipos_empresas: [],
        tipo_insumo:[],
        desc_tipo_insumo: [],
        tipos_modalidades: [],
        tipos_concess: [],
        tipos_pagamentos: [],
        tipos_classe_tarifaria: [],
        tipos_subclasse: [],
        tipos_tensao: [],
        tipos_ligacao: [],
        
      };
    },

    created() {
      this.initFilters();
    },
    mounted() {
      // funçoes importadas do arquivo funcoes.js
      this.listarFaturas();
      this.listarTipoInsumo();
      this.listarEmpresas();
      this.listarModalidadesTarifarias();
      this.listarTiposConcessionarias();
      this.listarPagamento();
      this.listarClasseTarifaria();
      this.listarSubclasse();
      this.listarTensao();
      this.listarLigacao();
     
      

    },


    methods: {
      openNew() {
        this.fatura = {};
        this.submitted = false;
        this.productDialog = true;
      },

      editProduct(fatura) {
        this.productDialog = true;
        this.fatura = { ...fatura };
      },

      editDadosMedidor(medidor) {
        this.modalEditMedidor = true;
        this.medidor = { ...medidor };
      },

      editDadosFiscais(dados_fiscais) {
        this.modalEditDadosFiscais = true;
        this.dados_fiscais = { ...dados_fiscais };
      },

      editDadosTributos(dados_tributos) {
        this.modalEditDadosTributos = true;
        this.dados_tributos = { ...dados_tributos };
      },

      editItensFaturados(itens_faturados) {
        this.modalEditItens = true;
        this.itens_faturados = { ...itens_faturados };
      },

      editDadosFatura(dados_faturas) {
        this.modalEditDados = true;
        this.dados_faturas = { ...dados_faturas };
      },

      hideDialog() {
        this.productDialog = false;
        this.submitted = false;
      },

      addDadosMedidor(medidor) {
        this.modalEditMedidor = true;
        this.medidor.id_fatura = medidor.id ;
      },

      addItensFaturados(itens_faturados) {
        this.modalEditItens = true;
        this.itens_faturados.id_fatura = itens_faturados.id ;
      },




      // criar/editar fatura
      salvarFatura() {
        this.submitted = true;

        if (this.fatura.id) {
          // Ediçao de fatura
          this.axios
            .post("/faturas/editar", this.fatura)
            .then(() => {

              // console.log(this.fatura)

              this.productDialog = false;
              this.fatura = {};
              this.listarFaturas();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar nova fatura
          this.axios
            .post("/faturas/adicionar", this.fatura)
            .then(() => {

              // alert(response.data.data)

              this.productDialog = false;
              this.fatura = {};
              this.listarFaturas();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

      salvarMedidorFatura() {
        this.submitted = true;
        console.log('começa')
        console.log(this.medidor)
        console.log('termina')

        if (this.medidor.id) {
          // Ediçao de medidor
          console.log('entrou editar')
          this.axios
            .post("/faturas/medidor/editar", this.medidor)
            .then(() => {

              // console.log(this.medidor)

              this.modalEditMedidor = false;
              this.medidor = {};
              this.listarFaturas();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar nova medidor
          console.log('entrou aqui')
          console.log(this.medidor)
          this.axios
            .post("/faturas/medidor/adicionar", this.medidor)
            .then(() => {

              // alert(response.data.data)

              this.modalEditMedidor = false;
              this.medidor = {};
              this.listarFaturas();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

      salvarItensFaturadosFatura() {
        this.submitted = true;

        if (this.itens_faturados.id) {
          // Ediçao de itens
          this.axios
            .post("/faturas/itens-faturados/editar", this.itens_faturados)
            .then(() => {

              // console.log(this.itens_faturados)

              this.modalEditItens = false;
              this.itens_faturados = {};
              this.listarFaturas();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar nova itens_faturados
          this.axios
            .post("/faturas/itens-faturados/adicionar", this.itens_faturados)
            .then(() => {

              // alert(response.data.data)

              this.modalEditItens = false;
              this.itens_faturados = {};
              this.listarFaturas();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

      salvarDadosFatura() {
        this.submitted = true;

        if (this.dados_faturas.id) {
          // Ediçao de itens
          this.axios
            .post("/faturas/dados/editar", this.dados_faturas)
            .then(() => {

              // console.log(this.dados_faturas)

              this.modalEditDados = false;
              this.dados_faturas = {};
              this.listarFaturas();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar nova dados_faturas
          this.axios
            .post("/faturas/dados/adicionar", this.dados_faturas)
            .then(() => {

              // alert(response.data.data)

              this.modalEditDados = false;
              this.dados_faturas = {};
              this.listarFaturas();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

      salvarDadosFiscaisFatura() {
        this.submitted = true;

        if (this.dados_fiscais.id) {
          // Ediçao de itens
          this.axios
            .post("/faturas/dados-fiscais/editar", this.dados_fiscais)
            .then(() => {

              // console.log(this.dados_fiscais)

              this.modalEditDados = false;
              this.dados_fiscais = {};
              this.listarFaturas();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar nova dados_fiscais
          this.axios
            .post("/faturas/dados-fiscais/adicionar", this.dados_fiscais)
            .then(() => {

              // alert(response.data.data)

              this.modalEditDados = false;
              this.dados_fiscais = {};
              this.listarFaturas();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

      salvarDadosTributosFatura() {
        this.submitted = true;

        if (this.dados_tributos.id) {
          // Ediçao de itens
          this.axios
            .post("/faturas/dados-tributos/editar", this.dados_tributos)
            .then(() => {

              // console.log(this.dados_tributos)

              this.modalEditDadosTributos = false;
              this.dados_tributos = {};
              this.listarFaturas();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar nova dados_tributos
          this.axios
            .post("/faturas/dados-tributos/adicionar", this.dados_tributos)
            .then(() => {

              // alert(response.data.data)

              this.modalEditDadosTributos = false;
              this.dados_tributos = {};
              this.listarFaturas();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },







      apagarFaturasModal(fatura) {
        this.fatura = fatura;
        this.deleteProductDialog = true;
      },

      apagarDadosMedidorModal(medidor_faturas) {
        this.medidor_faturas = medidor_faturas;
        this.deleteMedidorDialog = true;
      },

      apagarDadosFiscaisModal(dados_fiscais) {
        this.dados_fiscais = dados_fiscais;
        this.deleteDadosFiscaisDialog = true;
      },

      apagarDadosTributosModal(dados_tributos) {
        this.dados_tributos = dados_tributos;
        this.deleteDadosTributosDialog = true;
      },

      apagarDadosFaturaModal(dados_faturas) {
        this.dados_faturas = dados_faturas;
        this.deleteDadosDialog = true;
      },

      apagarItensFaturadosModal(itens_faturados) {
        this.itens_faturados = itens_faturados;
        this.deleteItensFaturadosDialog = true;
      },

      // botao de excluir selecionados
      confirmDeleteSelected() {
        this.deleteProductsDialog = true;
      },

      apagarFaturas() {
        this.axios
        // console.log(this.concessionaria)
          .post("/faturas/apagar", this.fatura)
          .then(() => {
            
            this.deleteProductDialog = false;
            this.fatura = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Fatura apagada com sucesso!",
              life: 3000,
            });
            
            this.listarFaturas();
              

          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
          
      },

      apagarItensFaturados() {
        this.axios
        // console.log(this.concessionaria)
          .post("/faturas/itens-faturados/apagar", this.itens_faturados)
          .then(() => {
            
            this.deleteItensFaturadosDialog = false;
            this.itens_faturados = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Item apagado com sucesso!",
              life: 3000,
            });
            
            this.listarFaturas();
              

          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
          
      },

      apagarDadosMedidor() {
        this.axios
        // console.log(this.concessionaria)
          .post("/faturas/medidor/apagar", this.medidor_faturas)
          .then(() => {
            
            this.deleteMedidorDialog = false;
            this.medidor_faturas = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Medidor apagado com sucesso!",
              life: 3000,
            });
            
            this.listarFaturas();
              

          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
          
      },

      apagarDados() {
        this.axios
        // console.log(this.concessionaria)
          .post("/faturas/dados/apagar", this.dados_faturas)
          .then(() => {
            
            this.deleteDadosDialog = false;
            this.dados_faturas = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Medidor apagado com sucesso!",
              life: 3000,
            });
            
            this.listarFaturas();
              

          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
          
      },

      apagarDadosFiscais() {
        this.axios
        // console.log(this.concessionaria)
          .post("/faturas/dados-fiscais/apagar", this.dados_fiscais)
          .then(() => {
            
            this.deleteDadosFiscaisDialog = false;
            this.dados_fiscais = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Dados Fiscais apagados com sucesso!",
              life: 3000,
            });
            
            this.listarFaturas();
              

          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
          
      },

      apagarDadosTributos() {
        this.axios
        // console.log(this.concessionaria)
          .post("/faturas/dados-tributos/apagar", this.dados_tributos)
          .then(() => {
            
            this.deleteDadosTributosDialog = false;
            this.dados_tributos = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Dados Tributos apagados com sucesso!",
              life: 3000,
            });
            
            this.listarFaturas();
              

          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
          
      },

      



      
      










      deleteSelectedProducts() {
        this.faturas = this.faturas.filter(
          (val) => !this.selectImplantacoes.includes(val)
        );
 
        let obj = this.selectImplantacoes;
        Object.keys(obj).forEach((key) => {
          this.axios
            .post("/faturas/apagar", obj[key])
            .then(() => {
              this.deleteProductDialog = false;
              this.fatura = {};
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "faturas apagadas com sucesso!",
                life: 3000,
              });
              this.listarFaturas();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        });
 
        this.deleteProductsDialog = false;
        this.selectImplantacoes = null;
      },
     
     exportCSV() {
       this.$refs.dt.exportCSV();
      },
      
      
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
      
    },
  };


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>